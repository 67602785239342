import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';
import { appsignal } from 'src/services/appsignal/client';

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <AppSignalErrorBoundary instance={appsignal}>
      {children}
    </AppSignalErrorBoundary>
  );
}
