import Appsignal from '@appsignal/javascript';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';

export const appsignal = new Appsignal({
  key: process.env.NEXT_PUBLIC_APP_SIGNAL_CLIENT_KEY,
  namespace: 'client',
  revision: process.env.NEXT_PUBLIC_REVISION,
  ignoreErrors: [
    // Google Maps Marker issue
    // https://appsignal.com/sportcity/sites/661e7b7583eb67d2d5441a89/exceptions/incidents/88
    /this.Ji.replaceChildren is not a function. (In 'this.Ji.replaceChildren(b)', 'this.Ji.replaceChildren' is undefined)/,
  ],
});

// Adds pathnames to the error context
appsignal.use(pathDecoratorPlugin());
