import styles from './index.module.scss';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { Body, H5 } from 'src/features/shared/components/typography';

export interface ITooltipProps {
  title: string;
  description: string;
  children?: React.ReactNode;
  triggerClassName?: string;
  tooltipClassName?: string;
  align?: RadixTooltip.TooltipContentProps['align'];
  onOpenChange?: (isOpen: boolean) => void;
}

async function sleep() {
  return new Promise((resolve) => setTimeout(resolve, 0));
}

export function Tooltip({
  title,
  description,
  children,
  triggerClassName,
  tooltipClassName,
  align,
  onOpenChange,
}: ITooltipProps) {
  return (
    <RadixTooltip.Root onOpenChange={onOpenChange}>
      <RadixTooltip.Trigger
        type="button"
        className={classNames(styles['tooltip-trigger'], triggerClassName)}
        onClick={async (event) => {
          event.preventDefault();
          event.stopPropagation();
          const target = event.currentTarget;
          if (!target) return;
          await sleep();
          target.blur();
          target.focus();
        }}
      >
        {children ?? <Icons.InfoCircleFilled className={styles.icon} />}
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={classNames(styles['tooltip-content'])}
          side="bottom"
          sideOffset={4}
          align={align}
        >
          <div
            className={classNames(
              styles['tooltip-container'],
              tooltipClassName,
            )}
          >
            <H5>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </H5>
            <Body>{description}</Body>
          </div>
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}

export const TooltipProvider = RadixTooltip.Provider;
